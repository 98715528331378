<template>
  <div class="liveLottery">
    <div v-title data-title="体育连胜"></div>

    <!-- <van-nav-bar
      title="真人闯关"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-Sportsgames.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img
          src="../assets/img/pcbb/rules_1.png"
          v-if="cueTheme == 'dattheme'"
        />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动说明:</p>
              <span style="color:var(--li-colortext);"
                >会员在指定场馆（IM体育、贝宝体育、OB体育、直播体育、抖音体育、沙巴体育）中的某一个游戏场馆内投注游戏，完成连赢8场，且满足8局总流水要求，即可获得对应的彩金。</span
              >
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table v-if="datalist[0]">
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
                <td
                  style="
                        color: #fff;
                        border-top-left-radius: 10px;
                        border-right: 1px solid #e2d2bf;
                      "
                >
                  {{ datalist[0][0] }}
                </td>
                <td
                  style="
                        color: #fff;
                        border-right: 1px solid #e2d2bf;
                        border-top-right-radius: 10px;
                      "
                >
                  {{ datalist[0][1] }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in datalist"
                :key="index"
                v-if="index != 0"
              >
                <td>≥{{ item.beat_limit }}</td>
                <td>{{ item.bonus_money }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                申请方式:
                会员可完成8连赢后找客服申请，并附上游戏内投注记录的截图，客服会提交至优惠中心审核，通过后奖金将于48小时发放，请注意查收。如会员在过关完成后24小时内未联系客服进行申请，视为主动放弃该活动奖金，活动奖金仅需一倍流水即可出款。
              </li>
              <li>
                <span>2.</span>
                凡是投注的体育注单出现提前结算（兑现、买回、跳车）的情况，连赢将被终止并重新计算。
              </li>
              <li>
                <span>3.</span>
                有效投注额仅对已结算并产生输赢结果的*注单*进行计算，任何平局、取消的赛事将不计算在有效投注，但不会终止连胜;（例如:下注100元，香港盘赔率0.6，如赢派彩60元，有效投注为60元；如输本金100元，
                有效投注为100元）
              </li>
              <li>
                <span>4.</span>
                任何低于欧洲盘1.5或香港盘0.5水位的投注将不计算在内，同个盘口多个注单不予计算，同场比赛多个盘口给予计算;（通过客服查询审核为准）
              </li>
              <li>
                <span>5.</span>
                如果在连赢期间存在下注虚拟体育/金融投注/彩票/串关，或者赛事被取消，走盘的注单，对连赢结果不产生影响，连赢场次依次顺延；
              </li>
              <li>
                <span>6.</span>
                流水统计会存在20分钟左右缓存时间，如会员发现申请时流水不正确可等待片刻后重试。
              </li>
              <li>
                <span>7.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>8.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取
                消其优惠资格；
              </li>
              <li>
                <span>9.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getdata } from '../network/api'

export default {
  data() {
    return {
      datalist: [],
      cueTheme: '',
    }
  },
  methods: {
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },
    getdata() {
      let data = '?type=sports'
      getdata(data).then((res) => {
        if (res.status_code === 200) {
          this.datalist = res.message
        }
      })
    },
  },
  mounted() {
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f7f8fa;
}

.liveLottery {
  .activityRules {
    position: relative;
    background-color: #fff;
    margin: 10px 15px;
    // padding-bottom: 1px;
    border-radius: 8px;

    .title {
      width: 100%;
      padding-top: 20px;
      text-align: center;
      color: #b2987f;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;
      padding-bottom: 5px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 70px;
        font-size: 12px;

        > span {
          text-align: center;
          position: absolute;
          top: 0px;
          left: 0;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c8a57e;
        }
      }
    }

    table {
      width: calc(100% - 30px);
      margin: 0 15px;
      text-align: center;
      border-collapse: separate;
      border-spacing: 0;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-family: PingFang SC;
      border-bottom: 2px solid #e2d2bf;
      font-weight: 400;
      color: #684728;

      th,
      td {
        height: 40px;
        padding: 10px 0;
        color: #684728;
        font-size: 13px;
        text-align: center;
      }
    }

    tr:nth-child(1) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    tr:nth-child(odd) {
      background-color: #f7f6f3;
    }

    td {
      border-right: 2px solid #e2d2bf;
    }

    td:nth-child(1) {
      border-left: 2px solid #e2d2bf;
    }
  }

  .Rules {
    margin: 10px 15px;
    // margin: 0 20px;
    // margin-top: 15px;
    font-family: PingFang SC;
    border-radius: 8px;
    background-color: #fff;

    .title {
      padding-top: 20px;
      width: 100%;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c8a57e;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 18px;
        font-size: 13px;
        line-height: 1.5em;

        > span {
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          left: 0;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
